/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
<template>
  <b-overlay
    :show="loading"
    opacity="0.3"
    variant="transparent"
    spinner-variant="primary"
    spinner-type="border"
    rounded="sm"
    style="min-height: 200px;"
    class="edit-offer"
  >
    <b-card>
      <b-form
        v-if="customTrip"
        @submit.prevent="submitForm"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Name"
              label-for="h-name"
              label-cols-md="4"
            >
              <b-form-input
                id="h-name"
                v-model="customTrip.trip.offers_trip.offerName"
                :disabled="true"
                placeholder="Name"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="User"
              label-for="h-user"
              label-cols-md="4"
            >
              <b-form-input
                id="h-user"
                v-model="customTrip.trip.offers_trip.user"
                :disabled="true"
                placeholder="User"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Adults"
              label-for="h-adults"
              label-cols-md="4"
            >
              <b-form-input
                id="h-adults"
                v-model="customTrip.trip.offers_trip.adults"
                :disabled="true"
                placeholder="Adults"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Children"
              label-for="h-children"
              label-cols-md="4"
            >
              <b-form-input
                id="h-children"
                v-model="customTrip.trip.offers_trip.children"
                :disabled="true"
                placeholder="Children"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Arrival date"
              label-for="h-arrival_date"
              label-cols-md="4"
            >
              <b-form-input
                id="h-arrival_date"
                v-model="customTrip.trip.offers_trip.arrival_date"
                :disabled="true"
                placeholder="Arrival date"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Departure date"
              label-for="h-departure_date"
              label-cols-md="4"
            >
              <b-form-input
                id="h-departure_date"
                v-model="customTrip.trip.offers_trip.departure_date"
                :disabled="true"
                placeholder="Departure date"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Users"
              label-for="h-users"
              label-cols-md="4"
            >
              <v-select
                v-model="customTrip.users"
                multiple
                label="display_name"
                :options="customTrip.users"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Add User"
              label-for="h-users"
              label-cols-md="4"
            >
              <v-select
                v-model="selectVendor"
                label="display_name"
                :options="availableUsers"
              />
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <b-col offset-md="4">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-overlay>
</template>

<script>
/* eslint-disable */

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'EditCustomTrip',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    vSelect,
    BOverlay,
  },
  props: [
    'customOfferId',
  ],
  data() {
    return {
      customTrip: '',
      loading: false,
      selectVendor: '',
    }
  },
  beforeMount() {
    this.loadCustomTrip()
  },
  computed: {
    availableUsers() {
      return this.$store.state.app.availableUsers
    }
  },
  methods: {
    async loadCustomTrip() {
      const self = this

      self.loading = true
      await this.$store.dispatch('app/fetchCustomTrip', self.customOfferId)
        .then(() => {
          self.customTrip = self.$store.state.app.customTrip
          self.customTrip.trip.offers_trip = JSON.parse(JSON.parse(self.customTrip.trip.offers_trip))
        })
        .catch(() => {
        })
        .finally(() => {
          self.loading = false
        })
    },
    async submitForm() {
      const self = this

      self.loading = true;
      await this.$store.dispatch('app/selectVendorCustomTrip', this.selectVendor).catch().then(() => {
        self.loading = true;
        self.$router.push('/custom-trips')
      })
    }
  },
}
</script>

<style lang="scss">
  .edit-offer {
    .vs--single {
      .vs__selected {
        color: #d0d2d6;
      }
    }
  }
</style>